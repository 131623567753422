<template>
  <div @click="$emit('click')" class="flex flex-col w-full my-2 text-input">
    <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
    <div class="flex flex-col justify-between w-full px-2">
      <p
        class="`
          flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-transparent
          min-w-0 text-left
        `"
      >
        {{value}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextDisplayComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: '',
      edit: false,
    };
  },
};
</script>
