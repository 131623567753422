var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"`\n  flex flex-row justify-around text-xl\n  border-b-2 bg-backfill rounded-t-lg text-content border-contrast\n`"},[_c('div',{staticClass:"flex-grow py-1 cursor-pointer left-tab",class:[_vm.selected == 0 ?
        'font-extrabold border-b-2 text-primary' : 'font-normal`',
        {'border-contrast': _vm.pulse !== 'left'},
        {'animate-pulse border-2 rounded-lg border-yellow-300':_vm.pulse === 'left'},
        _vm.centerTab ? 'w-1/3' : 'w-1/2'
      ],on:{"click":function($event){return _vm.tabClicked(0)}}},[_vm._v(" "+_vm._s(_vm.leftTab)+" ")]),(_vm.centerTab)?_c('div',{staticClass:"flex-grow py-1 cursor-pointer center-tab",class:[_vm.selected == 1 ?
        'font-extrabold border-b-2 text-primary' : 'font-normal',
        {'border-contrast': _vm.pulse !== 'center'},
        {'animate-pulse border-2 rounded-lg border-yellow-300':_vm.pulse === 'center'},
        {'w-1/3': _vm.centerTab}
      ],on:{"click":function($event){return _vm.tabClicked(1)}}},[_vm._v(" "+_vm._s(_vm.centerTab)+" ")]):_vm._e(),_c('div',{staticClass:"flex-grow py-1 cursor-pointer right-tab",class:[_vm.selected == 2 ?
        'font-extrabold border-b-2 text-primary' : 'font-normal',
        {'border-contrast': _vm.pulse !== 'right'},
        {'animate-pulse border-2 rounded-lg border-yellow-300':_vm.pulse === 'right'},
        _vm.centerTab ? 'w-1/3' : 'w-1/2'
      ],on:{"click":function($event){return _vm.tabClicked(2)}}},[_vm._v(" "+_vm._s(_vm.rightTab)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }