<template>
    <interior-page-content-container
      nav-selection="assistance"
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :loading="loading"
      :modal-show="modal.cancelAppointment"
      :notificationTime="notificationTime"
      :notificationType="notificationType"
      :notificationMessage.sync="notificationMessage"
    >

      <template #modal>
        <modal-confirmation
          v-if="modal.cancelAppointment"
          title="Cancel Appointment?"
          description="Are you sure you would like to cancel your free consultation?"
          text-confirm="Yes"
          text-cancel="No"
          @confirm="cancelAppointment"
          @cancel="modal.cancelAppointment = false"
        />
      </template>

      <template #title>
        Assistance
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-72 md:w-80 lg:w-96 max-w-md
          mb-10 pb-10
        `">

          <div class="``
            absolute top-0 w-72 md:w-80 lg:w-96 my-auto flex flex-row justify-center mx-auto
          ">
            <div
              class="`
                flex-grow-0 flex flex-col flex-shrink-0 pt-2 w-full bg-background
              `"
            >
              <tab-input
                leftTab="Q & A"
                rightTab="Consultation"
                :selected="selected"
                @update:selected="tabClick"
              />
              <div
                v-if="selected === 0"
                class="flex flex-row justify-center w-full mt-2"
              >
                <search-bar
                  :value.sync="search"
                  class="w-full"
                />
              </div>
            </div>
          </div>

          <div class="w-full h-full pb-3">
            <!-- Q & A -->
            <div
              v-if="selected === 0"
              class="w-full mt-14 declutter"
            >

              <q-and-a-component
                v-for="qAndA in filteredQAndAs" :key="qAndA.name"
                :id="qAndA.name"
                :name="qAndA.name"
                :description="qAndA.description"
                :url="qAndA.url"
                :open.sync="qAndA.open"
                class="mt-4"
              />
            </div>

            <!-- Appointment -->
            <div
              v-else-if="selected === 2"
              class="h-full services w-72 md:w-80 lg:w-96"
            >
              <div v-if="currentEvent !== null" class="flex flex-col items-center w-full">
                <div
                  class="w-full p-3 my-4 rounded-lg bg-backfill"
                >
                  <text-display-component
                    label="Topic"
                    :value="currentEvent.summary"
                  />

                  <text-display-component
                    v-if="currentEvent.description && currentEvent.description.trim().length > 0"
                    label="Description"
                    :value="currentEvent.description"
                  />

                  <text-display-component
                    label="Date"
                    :value="date"
                  />

                  <text-display-component
                    label="Time"
                    :value="time"
                  />

                </div>

                <button-large
                  @click="modal.cancelAppointment = true"
                  class="self-center my-7"
                  color="red"
                >
                  Cancel Appointment
                </button-large>
              </div>

              <div
                v-else
                class="flex flex-col h-full"
              >
                <div class="flex flex-col items-start justify-start flex-grow mt-10">

                  <p class="mb-3 font-medium text-left text-content">
                    We understand how you feel - downsizing, decluttering and providing for home
                    safety can be intimidating. KIV is here to provide professional advice to
                    help you successfully complete your project.
                  </p>
                  <p class="mb-3 font-medium text-left text-content">
                    We invite you to take advantage of our complimentary 30 minute consultation.
                    Simply click on the button below and choose a convenient time for an online
                    appointment with one of our KIV specialists.
                  </p>

                  <button-large
                    @click="scheduleAppointment"
                    color="primary"
                    class="self-center my-7"
                  >
                    Schedule Appointment
                  </button-large>
                </div>

              </div>
            </div>

            <div class="h-10 opacity-0">space...</div>
          </div>
        </div>

      </template>

    </interior-page-content-container>
</template>

<script>
import moment from 'moment';

import {
  USER_WALKTHROUGH_DISPATCH,
  EVENTS_GET, EVENT_DELETE,
} from '@/store/actions';
import { USER_WALKTHROUGH_STATE } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import SearchBar from '@/components/shared/inputs/SearchBar.vue';
import TabInput from '@/components/shared/inputs/TabInput.vue';
import QAndAComponent from '@/components/assistance/QAndAComponent.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';

import * as qAndAInfo from '@/assets/json/questionAndAnswers.json';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import TextDisplayComponent from '../../components/shared/general/TextDisplayComponent.vue';

export default {
  name: 'Assistance',
  components: {
    InteriorPageContentContainer,
    SearchBar,
    TabInput,
    QAndAComponent,
    ModalConfirmation,
    TextDisplayComponent,
    ButtonLarge,
  },
  data() {
    return {
      loading: true,
      selected: 2,
      search: '',
      qAndAs: [],
      currentEvent: null,
      modal: {
        cancelAppointment: false,
      },
      notificationTime: 5000,
      notificationType: 'success',
      notificationMessage: '',
    };
  },
  computed: {
    date() {
      return moment(
        this.currentEvent.start.dateTime,
      ).format('ddd MMM DD, YYYY');
    },
    time() {
      const start = moment(this.currentEvent.start.dateTime).format('h:mm A');
      const end = moment(this.currentEvent.end.dateTime).format('h:mm A');
      return `${start} - ${end}`;
    },
    filteredQAndAs() {
      return this.qAndAs.filter((qAndA) => qAndA.name.toLowerCase().includes(
        this.search.toLowerCase(),
      ));
    },
    walkthroughStage() {
      return this.$store.getters[USER_WALKTHROUGH_STATE];
    },
  },
  watch: {
    $route(newVal) {
      if (newVal.params.tab.toLowerCase() === 'q-and-a') this.selected = 0;
      else if (newVal.params.tab.toLowerCase() === 'appointment') this.selected = 2;
    },
    walkthroughStage() {
      this.onWalkthrough();
    },
    notificationMessage(newVal) {
      if (newVal.length === 0 && this.$store.getters[USER_WALKTHROUGH_STATE] === 20) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
  created() {
    this.qAndAs = qAndAInfo.qAndAs.map((qAndA) => ({
      ...qAndA,
      open: this.$route.query.openQAndA === qAndA.name,
    }));

    this.$store.dispatch(EVENTS_GET)
      .then((events) => {
        this.currentEvent = events.length > 0 ? { ...events[0] } : null;
        this.loading = false;
      });
  },
  mounted() {
    if (this.$route.query.openQAndA !== null && this.$route.query.openQAndA !== undefined) {
      document.getElementById(this.$route.query.openQAndA).scrollIntoView();
    }

    const { tab } = this.$route.params;
    // Collects the tab
    if (!tab || (tab.toLowerCase() !== 'appointment' && tab.toLowerCase() !== 'q-and-a')) {
      this.$router.push({
        name: 'Assistance',
        params: { tab: 'q-and-a' },
      });
    }
    this.onWalkthrough();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'Dashboard') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    onWalkthrough() {
      if (this.$store.getters[USER_WALKTHROUGH_STATE] === 20) {
        this.notificationTime = 5000;
        this.notificationType = 'success';
        this.notificationMessage = 'Book your consultation and view Q&As here!';
      }
    },
    backNav() {
      this.$router.go(-1);
    },
    tabClick(newVal) {
      this.selected = newVal;

      let newTab = '';
      if (newVal === 0) newTab = 'q-and-a';
      if (newVal === 2) newTab = 'appointment';

      this.$router.push({
        name: 'Assistance',
        params: {
          tab: newTab,
        },
      });
    },
    cancelAppointment() {
      this.$store.dispatch(EVENT_DELETE, this.currentEvent.id);
      this.currentEvent = null;
      this.modal.cancelAppointment = false;
    },
    scheduleAppointment() {
      this.$router.push({
        name: 'AssistanceBooking',
      });
    },
  },
};
</script>
