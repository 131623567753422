<template>
  <modal-container
    @cancel="$emit('cancel')"
  >
    <p class="text-xl font-bold text-left text-primary">{{title}}</p>
    <p class="pt-2 font-bold text-left">{{description}}</p>
    <div class="flex flex-row justify-end w-full pt-8">

      <button-large
        v-if="!!textCancel"
        @click="$emit('cancel')"
        color="secondary"
        class="w-32"
      >
        {{textCancel}}
      </button-large>

      <button-large
        v-if="!!textConfirm"
        @click="$emit('confirm')"
        color="red"
        class="w-32 ml-5"
      >
        {{textConfirm}}
      </button-large>

    </div>
  </modal-container>
</template>

<script>
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'ModalConfirmation',
  components: {
    ButtonLarge,
    ModalContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    textCancel: {
      type: String,
      required: false,
    },
    textConfirm: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>

</style>
