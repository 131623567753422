<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg w-full bg-backfill cursor-default
      active:shadow-none
      q-and-a-component
    `"
  >
    <div class="flex flex-col items-start w-full px-2">

      <div
        @click="$emit('update:open', !open)"
        class="flex items-center w-full px-2 pt-2 cursor-pointer"
      >
        <p class="flex-grow font-bold text-left text-primary">{{name}}</p>
        <div
          class="flex justify-end ml-2 text-content toggle-expansion"
        >
          <chevron-up v-if="open" />
          <chevron-down v-else />
        </div>
      </div>

      <div
        class="w-full mb-2 overflow-hidden transition-all duration-500 ease-in-out max-h-0"
        :class="{'max-h-960' : open}"
      >
        <div class="w-full p-2 font-bold text-left text-content">
          {{description}}
        </div>
        <iframe
          v-if="url"
          :src="url"
          height="400"
          class="w-full"
          frameborder="0"
          :allow="`
            accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture`"
          allowfullscreen
        ></iframe>
      </div>

    </div>
  </div>
</template>

<script>

import ChevronUp from 'vue-material-design-icons/ChevronUp.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';

export default {
  name: 'QAndAComponent',
  components: {
    ChevronUp,
    ChevronDown,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
