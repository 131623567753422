<template>
  <div class="`
    flex flex-row justify-around text-xl
    border-b-2 bg-backfill rounded-t-lg text-content border-contrast
  `">
      <div
        @click="tabClicked(0)"
        class="flex-grow py-1 cursor-pointer left-tab"
        :class="[selected == 0 ?
          'font-extrabold border-b-2 text-primary' : 'font-normal`',
          {'border-contrast': pulse !== 'left'},
          {'animate-pulse border-2 rounded-lg border-yellow-300':pulse === 'left'},
          centerTab ? 'w-1/3' : 'w-1/2'
        ]"
      >
        {{ leftTab }}
      </div>
      <div
        @click="tabClicked(1)"
        v-if="centerTab"
        class="flex-grow py-1 cursor-pointer center-tab"
        :class="[selected == 1 ?
          'font-extrabold border-b-2 text-primary' : 'font-normal',
          {'border-contrast': pulse !== 'center'},
          {'animate-pulse border-2 rounded-lg border-yellow-300':pulse === 'center'},
          {'w-1/3': centerTab}
        ]"
      >
        {{ centerTab }}
      </div>
      <div
        @click="tabClicked(2)"
        class="flex-grow py-1 cursor-pointer right-tab"
        :class="[selected == 2 ?
          'font-extrabold border-b-2 text-primary' : 'font-normal',
          {'border-contrast': pulse !== 'right'},
          {'animate-pulse border-2 rounded-lg border-yellow-300':pulse === 'right'},
          centerTab ? 'w-1/3' : 'w-1/2'
        ]"
      >
        {{ rightTab }}
      </div>
  </div>
</template>

<script>
export default {
  name: 'TabInput',
  props: {
    leftTab: {
      type: String,
      required: true,
    },
    centerTab: {
      type: String,
      required: false,
    },
    rightTab: {
      type: String,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
      validate: (val) => val <= 2,
    },
    pulse: {
      type: String,
      required: false,
      default: '',
      validate: (val) => ['left', 'center', 'right', ''].includes(val),
    },
  },
  methods: {
    tabClicked(tabId) {
      if (tabId !== this.selected) {
        this.$emit('update:selected', tabId);
      }
    },
  },
};
</script>

<style>

</style>
